<template>
  <div>
    <div class="sctp-container sctp-pad-tb20">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务中心</el-breadcrumb-item>
        <el-breadcrumb-item>订单</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="sctp-bg-white sctp-pad-tb20 sctp-mar-t15">
        <div style="width: 80%;margin: 0 auto">
          <el-steps :active="currStep" align-center>
            <el-step title="确认订单信息" description=""></el-step>
            <el-step title="签订合同和托管资金" description=""></el-step>
            <el-step title="服务商提供服务" description=""></el-step>
            <el-step title="付款" description=""></el-step>
            <el-step title="评价" description=""></el-step>
          </el-steps>
          <router-view @finish="onFinish"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'service_buy',
  data() {
    return {
      finish: false,
    }
  },
  computed: {
    currStep() {
      return [
        '/service/buy/confirmOrder',
        '/service/buy/signingContract',
        '/service/buy/confirmService',
        '/service/buy/pay',
        '/service/buy/evaluate',
      ].indexOf(this.$route.path) + (this.finish ? 1 : 0);
    }
  },
  methods: {
    onFinish() {
      this.finish = true;
    }
  },
  beforeMount() {
  }
}
</script>

<style scoped lang="scss">
</style>
